<!--
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 09:16:00
 * @FilePath: \zyt-mobile-frontend\src\views\me\evaluateQr\index.vue
-->
<template>
  <div class="qrcode">
    <div class="qrcode-content">
      <div class="blue">
        <span>扫码评价</span>
      </div>
      <div class="white" @click="ckQrcode">
        <img src="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd8a9099.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'evaluateQr',
  methods : {
    ckQrcode() {
      console.log('ckQrcode')
      window.location.href = 'http://zytmobile.dev.zhijiangfilm.com/#/sessionEvaluate?type=personal'
    }
  }
}
</script>

<style scoped lang="less">
.qrcode {
  min-height: 100vh;
  background-color: #f6f7f8;
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 80%;
    .blue {
      height: 32px;
      line-height: 32px;
      background: #5c90ff;
      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0px 1px 5px rgba(82, 116, 197, 0.5);
        padding-left: 10px;
      }
    }
    .white {
      height: 300px;
      background: #fff;
      position: relative;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
      }
    }
  }
}
</style>
